// Use this to write your custom SCSS
.header_otix_mega_menu {
    display: grid !important;
    width: 360px;
    grid-template-columns: 50px 1fr;
    align-items: center;
    text-decoration: none;
    padding: 0 var(--pd-puco-spacing-m) 0 var(--pd-puco-spacing-s);
    border-radius: var(--pd-radius-m);
    min-height: 80px;
}
.d-grid {
    display: grid;
}
.products_nav_menu_item:hover {
    background-color: #f7f9f9;
}
.products_nav_menu_item_icon {
    border: 1px solid #2065d1;
    border-radius: 40px;
    padding: 8px;
    margin-right: 8px;
}
.products_nav_menu_item_title {
    font-weight: 600 !important;
    font-size: 14px;
}
.products_nav_menu_item_description {
    font-weight: 300 !important;
    font-size: 13px;
    white-space: initial;
}
.dropdown-item:hover {
    color: inherit;
}
.nav-link:hover {
    cursor: pointer;
}
.navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 0.8rem;
    margin-left: 0.8rem;
}
@media only screen and (min-width: 768px) {
    .pointers-card-body-330 {
        min-height: 330px;
    }
    .pointers-card-body-360 {
        min-height: 360px;
    }
}

.popular-pricing-card {
    border: 2px solid #4C82F7;
}
.most-popular-package {
    background-color: rgba(0, 184, 217, 0.16);
    padding: 6px 20px;
    border-radius: 50px;
    // color: #fff;
    // font-weight: 700;
    position: absolute;
    top: 10px;
    right: 10px;
}
.pricing-comparison-tooltip {
    text-decoration: underline dotted;
    text-underline-offset: 4px;
}